<template>
    <div>
        <div @click="$router.push('/mypage/1/profile')">내정보</div>
        <div @click="$router.push('/mypage/1/alarm')">알림</div>
        <div @click="$router.push('/mypage/1/myactive')">나의 활동</div>
    </div>
</template>

<script>
    export default {
        name: "MenteeUserSide",
        mixins: [],
        components: {
        },
        props: {

        },
        data() {
            return {
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {z
        },
        methods:{
            backEvent() {

            },

        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>